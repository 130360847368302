import React from 'react';

function Home() {
  // Componente para el CDN de Tailwind
  const TailwindCDN = () => (
    <>
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css"
        rel="stylesheet"
      />
      <script src="https://cdn.tailwindcss.com"></script>
    </>
  );

  return (
    <>
      <TailwindCDN />
      <div className="min-h-screen bg-red-500 text-white">
        {/* Encabezado */}
        <header className="bg-red-600 text-white py-6 shadow-md">
          <h1 className="text-4xl font-bold text-center">
            Bienvenido al Sistema
          </h1>
        </header>

        {/* Contenido */}
        <main className="flex flex-col items-center justify-center p-8">
          <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg text-center text-gray-800">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">
              Sistema de Gestión
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Administra y optimiza tus rutas de distribución con facilidad.
            </p>

            {/* Sección adicional */}
            <div className="p-6 bg-gray-100 rounded-lg shadow-inner">
              <p className="text-gray-700 text-base">
                Este sistema está diseñado para ayudarte a gestionar tus rutas y 
                recursos de manera más eficiente, ahorrando tiempo y costos.
              </p>
            </div>

            {/* Botón de acción */}
            <div className="mt-8">
              <button className="px-6 py-3 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 transition duration-300 shadow-md">
                Comenzar
              </button>
            </div>
          </div>
        </main>
      </div>

      <div className="bg-green-500 py-6">
        {/* Footer o contenido adicional */}
      </div>
    </>
  );
}

export default Home;
