import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Welcome from './components/Welcome';
import Register from './components/Register';
import Inventory from './components/Inventory';
import RoutesPage from './components/Routes';
import Dashboard from './components/Dashboard';
import Home from './components/Home';

const App = () => {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
};

const MainLayout = () => {
  const location = useLocation(); // Hook para obtener la ubicación actual de la ruta

  // Ocultar el menú si estamos en las rutas de Login o Register
  const hideMenu = location.pathname === '/login' || location.pathname === '/register';

  return (
    <div>
      {/* Renderiza el menú solo si no estamos en la página de Login o Register */}
      {!hideMenu && (
        <nav className="bg-gray-300 p-4 flex justify-center space-x-4">
          <Link to="/" className="px-4 py-2 bg-gray-100 rounded">Inicio</Link>
          <Link to="/welcome" className="px-4 py-2 bg-gray-100 rounded">Bienvenida</Link>
          <Link to="/register" className="px-4 py-2 bg-gray-100 rounded">Registro</Link>
          <Link to="/inventory" className="px-4 py-2 bg-gray-100 rounded">Inventario</Link>
          <Link to="/routes" className="px-4 py-2 bg-gray-100 rounded">Rutas</Link>
          <Link to="/dashboard" className="px-4 py-2 bg-gray-100 rounded">Dashboard</Link>
        </nav>
      )}

      {/* Rutas de la aplicación */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/register" element={<Register />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/routes" element={<RoutesPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
};

export default App;
