import React, { useState } from 'react';

function Dashboard() {
  const [pendingInvoices, setPendingInvoices] = useState([]); // Facturas pendientes
  const [completedInvoices, setCompletedInvoices] = useState([]); // Facturas completadas
  const [newInvoice, setNewInvoice] = useState({
    id: '',
    clientName: '',
    amount: '',
    dueDate: '',
  });

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    setNewInvoice({ ...newInvoice, [e.target.name]: e.target.value });
  };

  // Agregar una nueva factura a pendientes
  const handleAddInvoice = (e) => {
    e.preventDefault();

    if (!newInvoice.id || !newInvoice.clientName || !newInvoice.amount || !newInvoice.dueDate) {
      alert('Por favor, completa todos los campos.');
      return;
    }

    setPendingInvoices([...pendingInvoices, newInvoice]); // Agregar a pendientes
    setNewInvoice({ id: '', clientName: '', amount: '', dueDate: '' }); // Reiniciar formulario
  };

  // Mover una factura a la tabla de completados
  const handleCompleteInvoice = (id) => {
    const invoiceToComplete = pendingInvoices.find((invoice) => invoice.id === id);
    setPendingInvoices(pendingInvoices.filter((invoice) => invoice.id !== id)); // Eliminar de pendientes
    setCompletedInvoices([...completedInvoices, invoiceToComplete]); // Agregar a completadas
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <header className="bg-gray-800 text-white p-4 text-center">
        <h1 className="text-2xl font-bold">Facturacion - Gestión de Facturas</h1>
      </header>

      <main className="container mx-auto mt-6">
        {/* Formulario para agregar facturas */}
        <div className="bg-white p-6 rounded shadow mb-6">
          <h2 className="text-xl font-bold mb-4">Agregar Nueva Factura</h2>
          <form onSubmit={handleAddInvoice} className="flex flex-col space-y-4">
            <input
              type="text"
              name="id"
              value={newInvoice.id}
              onChange={handleChange}
              placeholder="ID de Factura"
              className="p-2 border rounded"
              required
            />
            <input
              type="text"
              name="clientName"
              value={newInvoice.clientName}
              onChange={handleChange}
              placeholder="Nombre del Cliente"
              className="p-2 border rounded"
              required
            />
            <input
              type="number"
              name="amount"
              value={newInvoice.amount}
              onChange={handleChange}
              placeholder="Monto Total"
              className="p-2 border rounded"
              required
            />
            <input
              type="date"
              name="dueDate"
              value={newInvoice.dueDate}
              onChange={handleChange}
              className="p-2 border rounded"
              required
            />
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 rounded"
            >
              Agregar Factura
            </button>
          </form>
        </div>

        {/* Tabla de facturas pendientes */}
        <div className="bg-white p-6 rounded shadow mb-6">
          <h2 className="text-xl font-bold mb-4">Facturas Pendientes</h2>
          {pendingInvoices.length === 0 ? (
            <p className="text-gray-600">No hay facturas pendientes.</p>
          ) : (
            <table className="min-w-full border-collapse border border-gray-300">
              <thead className="bg-gray-100">
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Cliente</th>
                  <th className="border px-4 py-2">Monto</th>
                  <th className="border px-4 py-2">Fecha de Vencimiento</th>
                  <th className="border px-4 py-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {pendingInvoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td className="border px-4 py-2">{invoice.id}</td>
                    <td className="border px-4 py-2">{invoice.clientName}</td>
                    <td className="border px-4 py-2">${invoice.amount}</td>
                    <td className="border px-4 py-2">{invoice.dueDate}</td>
                    <td className="border px-4 py-2 text-center">
                      <button
                        onClick={() => handleCompleteInvoice(invoice.id)}
                        className="bg-green-500 hover:bg-green-700 text-white py-1 px-3 rounded"
                      >
                        Completado
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Tabla de facturas completadas */}
        <div className="bg-white p-6 rounded shadow">
          <h2 className="text-xl font-bold mb-4">Facturas Completadas</h2>
          {completedInvoices.length === 0 ? (
            <p className="text-gray-600">No hay facturas completadas.</p>
          ) : (
            <table className="min-w-full border-collapse border border-gray-300">
              <thead className="bg-gray-100">
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Cliente</th>
                  <th className="border px-4 py-2">Monto</th>
                  <th className="border px-4 py-2">Fecha de Vencimiento</th>
                </tr>
              </thead>
              <tbody>
                {completedInvoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td className="border px-4 py-2">{invoice.id}</td>
                    <td className="border px-4 py-2">{invoice.clientName}</td>
                    <td className="border px-4 py-2">${invoice.amount}</td>
                    <td className="border px-4 py-2">{invoice.dueDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
