import React from 'react';
import { useNavigate } from 'react-router-dom';

function Welcome() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-between min-h-screen">
      {/* Encabezado Rojo */}
      <div className="w-full bg-red-600 text-white py-6 text-center">
        <h1 className="text-3xl font-bold">Bienvenido al Sistema de Gestión de Rutas</h1>
        <h2 className="text-xl">Frutería Hermanos Ayala</h2>
      </div>

      {/* Contenido Principal */}
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        <h1 className="text-2xl font-bold mb-6 text-gray-800">
          Elija una opción
        </h1>

        <div className="space-y-4">
          <button
            className="w-full bg-green-500 text-white py-3 px-6 rounded-lg hover:bg-green-600 transition duration-300 font-medium shadow-md"
            onClick={() => navigate('/register')}
          >
            Registrar
          </button>

          <button
            className="w-full bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600 transition duration-300 font-medium shadow-md"
            onClick={() => navigate('/inventory')}
          >
            Inventario
          </button>

          <button
            className="w-full bg-indigo-500 text-white py-3 px-6 rounded-lg hover:bg-indigo-600 transition duration-300 font-medium shadow-md"
            onClick={() => navigate('/routes')}
          >
            Rutas
          </button>

          <button
            className="w-full bg-red-500 text-white py-3 px-6 rounded-lg hover:bg-red-600 transition duration-300 font-medium shadow-md mt-8"
            onClick={() => navigate('/logout')}
          >
            Cerrar Sesión
          </button>
        </div>
      </div>

      {/* Pie de Página Verde */}
      <div className="w-full bg-green-600 text-white py-4 text-center">
        <p className="text-sm">Frutería Hermanos Ayala &copy; 2024</p>
      </div>
    </div>
  );
}

export default Welcome;
