import React, { useState, useEffect } from 'react';

function Inventory() {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    code: '',
    name: '',
    price: '',
    wholesale_price: '',
    available: '',
    tax: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showForm, setShowForm] = useState(false); // Controlar visibilidad del formulario

  // Cargar productos desde la API
  const loadProducts = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/api/products');
      if (!response.ok) throw new Error('Error al cargar los productos');
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      setErrorMessage(error.message || 'Error al conectar con la API');
    }
  };

  useEffect(() => {
    loadProducts();
  }, []);

  // Manejar cambios en el formulario
  const handleChange = (e) => {
    setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
  };

  // Agregar o actualizar productos
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    const url = isUpdating
      ? `http://127.0.0.1:8000/api/products/${productToUpdate.id}`
      : 'http://127.0.0.1:8000/api/products';
    const method = isUpdating ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newProduct),
      });
      const data = await response.json();

      if (response.ok) {
        setSuccessMessage(
          isUpdating
            ? 'Producto actualizado correctamente'
            : 'Producto agregado correctamente'
        );
        loadProducts();
        setNewProduct({
          code: '',
          name: '',
          price: '',
          wholesale_price: '',
          available: '',
          tax: '',
        });
        setIsUpdating(false);
        setShowForm(false); // Ocultar formulario después de guardar
      } else {
        setErrorMessage(data.message || 'Error al procesar la solicitud');
      }
    } catch (error) {
      setErrorMessage('Error al conectar con el servidor');
    }
  };

  // Eliminar producto
  const handleDeleteProduct = async (id) => {
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await fetch(`http://127.0.0.1:8000/api/products/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setSuccessMessage('Producto eliminado correctamente');
        loadProducts(); // Recargar la lista de productos
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'Error al eliminar el producto');
      }
    } catch (error) {
      setErrorMessage('Error al conectar con el servidor');
    }
  };

  // Filtrar productos por búsqueda
  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Inventario</h1>

      {/* Mensajes */}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

      {/* Barra de búsqueda */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar por código o nombre"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 w-full border border-gray-300 rounded"
        />
      </div>

      {/* Botón desplegable para agregar/editar producto */}
      <div className="mb-6">
        <button
          onClick={() => setShowForm(!showForm)}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          {showForm ? 'Cerrar Formulario' : 'Agregar/Editar Producto'}
        </button>
        {showForm && (
          <div className="mt-4 p-4 border border-gray-300 rounded bg-gray-50">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="code"
                placeholder="Código"
                value={newProduct.code}
                onChange={handleChange}
                className="mb-2 p-2 w-full"
              />
              <input
                type="text"
                name="name"
                placeholder="Nombre"
                value={newProduct.name}
                onChange={handleChange}
                className="mb-2 p-2 w-full"
              />
              <input
                type="number"
                name="price"
                placeholder="Precio"
                value={newProduct.price}
                onChange={handleChange}
                className="mb-2 p-2 w-full"
              />
              <input
                type="number"
                name="wholesale_price"
                placeholder="Precio de mayoreo"
                value={newProduct.wholesale_price}
                onChange={handleChange}
                className="mb-2 p-2 w-full"
              />
              <input
                type="number"
                name="available"
                placeholder="Cantidad disponible"
                value={newProduct.available}
                onChange={handleChange}
                className="mb-2 p-2 w-full"
              />
              <input
                type="number"
                name="tax"
                placeholder="IVA"
                value={newProduct.tax}
                onChange={handleChange}
                className="mb-2 p-2 w-full"
              />
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 w-full"
              >
                {isUpdating ? 'Actualizar Producto' : 'Agregar Producto'}
              </button>
            </form>
          </div>
        )}
      </div>

      {/* Lista de productos */}
      <h2 className="text-xl font-semibold mb-4">Lista de Productos</h2>
      <table className="min-w-full border-collapse shadow-lg">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-4 py-2">Código</th>
            <th className="border px-4 py-2">Nombre</th>
            <th className="border px-4 py-2">Precio</th>
            <th className="border px-4 py-2">Precio de Mayoreo</th>
            <th className="border px-4 py-2">Cantidad Disponible</th>
            <th className="border px-4 py-2">IVA</th>
            <th className="border px-4 py-2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product) => (
            <tr key={product.id}>
              <td className="border px-4 py-2">{product.code}</td>
              <td className="border px-4 py-2">{product.name}</td>
              <td className="border px-4 py-2">${product.price}</td>
              <td className="border px-4 py-2">${product.wholesale_price}</td>
              <td className="border px-4 py-2">{product.available}</td>
              <td className="border px-4 py-2">{product.tax}%</td>
              <td className="border px-4 py-2 flex space-x-2">
                <button
                  onClick={() => {
                    setIsUpdating(true);
                    setProductToUpdate(product);
                    setNewProduct({
                      code: product.code,
                      name: product.name,
                      price: product.price,
                      wholesale_price: product.wholesale_price,
                      available: product.available,
                      tax: product.tax,
                    });
                    setShowForm(true);
                  }}
                  className="bg-blue-500 text-white px-2 py-1"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDeleteProduct(product.id)}
                  className="bg-red-500 text-white px-2 py-1"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Inventory;
