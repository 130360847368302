import React, { useState, useEffect } from 'react';

function Routes() {
  const [routes, setRoutes] = useState([]);
  const [newRoute, setNewRoute] = useState({
    name: '',
    description: ''
  });
  const [editRoute, setEditRoute] = useState(null); // Estado para la ruta en edición
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Cargar rutas desde la API
  const loadRoutes = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/api/routes');
      if (!response.ok) {
        throw new Error('Error al cargar las rutas');
      }
      const data = await response.json();
      setRoutes(data);
    } catch (error) {
      setErrorMessage(error.message || 'Error al conectar con la API');
    }
  };

  // Cargar rutas al montar el componente
  useEffect(() => {
    loadRoutes();
  }, []);

  // Manejar cambios en el formulario
  const handleChange = (e) => {
    setNewRoute({ ...newRoute, [e.target.name]: e.target.value });
  };

  // Agregar una nueva ruta
  const handleAddRoute = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await fetch('http://127.0.0.1:8000/api/routesstore', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newRoute),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage('Ruta agregada correctamente');
        loadRoutes(); // Recargar rutas después de agregar
        setNewRoute({ name: '', description: '' }); // Limpiar formulario
      } else {
        setErrorMessage(data.message || 'Error al agregar la ruta');
      }
    } catch (error) {
      setErrorMessage('Error al conectar con el servidor');
    }
  };

  // Editar una ruta
  const handleEditRoute = (route) => {
    setEditRoute(route); // Establece la ruta que se va a editar
  };

  // Manejar cambios en el formulario de edición
  const handleEditChange = (e) => {
    setEditRoute({ ...editRoute, [e.target.name]: e.target.value });
  };

  // Actualizar la ruta
  const handleUpdateRoute = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await fetch(`http://127.0.0.1:8000/api/routes/${editRoute.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editRoute),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage('Ruta actualizada correctamente');
        loadRoutes(); // Recargar rutas después de actualizar
        setEditRoute(null); // Limpiar el formulario de edición
      } else {
        setErrorMessage(data.message || 'Error al actualizar la ruta');
      }
    } catch (error) {
      setErrorMessage('Error al conectar con el servidor');
    }
  };

  // Eliminar una ruta
  const handleDeleteRoute = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta ruta?')) {
      try {
        const response = await fetch(`http://127.0.0.1:8000/api/routes/${id}`, {
          method: 'DELETE',
        });

        const data = await response.json();

        if (response.ok) {
          setSuccessMessage('Ruta eliminada correctamente');
          loadRoutes(); // Recargar rutas después de eliminar
        } else {
          setErrorMessage(data.message || 'Error al eliminar la ruta');
        }
      } catch (error) {
        setErrorMessage('Error al conectar con el servidor');
      }
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Rutas</h1>

      {/* Mostrar mensajes de éxito o error */}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

      {/* Formulario para agregar o editar una ruta */}
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">{editRoute ? 'Editar Ruta' : 'Agregar Ruta'}</h2>
        
        <input
          type="text"
          name="name"
          placeholder="Nombre de la Ruta"
          value={editRoute ? editRoute.name : newRoute.name}
          onChange={editRoute ? handleEditChange : handleChange}
          className="mb-2 p-2 w-full"
        />
        <input
          type="text"
          name="description"
          placeholder="Descripción"
          value={editRoute ? editRoute.description : newRoute.description}
          onChange={editRoute ? handleEditChange : handleChange}
          className="mb-2 p-2 w-full"
        />
        <button
          onClick={editRoute ? handleUpdateRoute : handleAddRoute}
          className="bg-green-500 text-white p-2 w-full mt-2"
        >
          {editRoute ? 'Actualizar Ruta' : 'Agregar Ruta'}
        </button>
        {editRoute && (
          <button
            onClick={() => setEditRoute(null)} // Limpiar edición
            className="bg-gray-500 text-white p-2 w-full mt-2"
          >
            Cancelar Edición
          </button>
        )}
      </div>

      {/* Mostrar rutas */}
      <div>
        <h2 className="text-xl font-semibold">Lista de Rutas</h2>
        <ul>
          {routes.map((route) => (
            <li key={route.id} className="mb-4 flex justify-between items-center">
              <div>
                {route.name} - {route.description}
              </div>
              <div>
                <button
                  onClick={() => handleEditRoute(route)}
                  className="bg-blue-500 text-white p-2 ml-2"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDeleteRoute(route.id)}
                  className="bg-red-500 text-white p-2 ml-2"
                >
                  Eliminar
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Routes;
